<template>
  <div class="px-4 pb-4 sm:px-6 sm:pb-6">
    <h1 class="my-2">{{ t("headings.bulletins") }}</h1>
    <breadcrumb class="mt-3 mb-4" />
    <div class="card">
      <custom-table
        :fields="fields"
        :items="bulletins"
        :loading="waiting"
        :pagination="true"
        :perPage="10"
        :searchable="true"
        :searchFields="searchFields"
        :showHeader="true"
        sortBy="createdAt"
        :ascending="false"
        :sortFields="sortFields"
        @row-click="(id) => router.push(`/bulletins/${id}`)"
      >
        <template #header>
          <span class="flex justify-end"
            ><form-button
              :command="() => router.push('/bulletins/create')"
              :icon="PlusIcon"
              id="header-bulletin-create-button"
              label="create_bulletin"
              :textVariant="company.primaryText"
              type="button"
              :variant="company.primary"
          /></span>
        </template>
        <template #cell(createdAt)="{ value }">
          {{ getLocaleDate(locale, value) }}
        </template>
        <template #cell(estates)="{ value }">
          <span v-for="(estate, index) in value" :key="estate"
            >{{ estate.name }}{{ index < value.length - 1 ? ", " : null }}</span
          >
        </template>
        <template #cell(roles)="{ value }">
          <span v-for="(role, index) in value" :key="role.id"
            >{{ t(`roles.${role.label}`)
            }}{{ index < value.length - 1 ? ", " : null }}</span
          >
        </template>
        <template #cell(sent)="{ value }">
          <span v-if="!value || !value.length">{{ t("labels.no") }}</span>
          <div v-else>
            <span v-for="(method, index) in value" :key="method"
              >{{ t(`messaging_methods.${method}`)
              }}{{ index < value.length - 1 ? ", " : null }}</span
            >
          </div>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { KeyIcon, PlusIcon } from "@heroicons/vue/24/solid";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import Breadcrumb from "../components/breadcrumb/Breadcrumb.vue";
import FormButton from "../components/form/FormButton.vue";
import CustomTable from "../components/table/CustomTable.vue";
import { getLocaleDate } from "../utils/dateUtils";
export default {
  components: { Breadcrumb, CustomTable, FormButton, KeyIcon, PlusIcon },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { locale, t } = useI18n();

    const fields = ref([
      {
        key: "topic",
        label: "topic",
      },
      {
        key: "createdAt",
        label: "date",
      },
      {
        key: "estates",
        label: "estates",
      },
      {
        key: "roles",
        label: "groups",
      },
      {
        key: "sent",
        label: "sent",
      },
    ]);
    const searchFields = ref(["topic", "createdAt"]);
    const sortFields = ref(["topic", "createdAt"]);

    const bulletins = computed(() => store.state.bulletin.bulletins);
    const company = computed(() => store.state.company.company);
    const user = computed(() => store.state.authentication.user);

    const waiting = computed(() => {
      if (store.state.bulletin.waiting) return true;
      if (store.state.company.waiting) return true;
      if (store.state.authentication.waiting) return true;
      return false;
    });

    onMounted(() => {
      if (!bulletins.value.length || bulletins.value.length < 10) {
        store.dispatch("bulletin/getBulletins", user.value.companyId);
      }
    });

    return {
      bulletins,
      company,
      fields,
      getLocaleDate,
      locale,
      PlusIcon,
      router,
      searchFields,
      sortFields,
      t,
      waiting,
    };
  },
};
</script>
